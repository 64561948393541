import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Connect from './components/connect';
import HowItWorks from './components/how';
import Categories from './components/category';
import Footer from './components/foot';
import ExpertList from './components/ExpertList';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={
              <>
                <Connect />
                <HowItWorks />
                <Categories />
                <Footer />
              </>
            } />
            <Route path="/experts" element={
              <>
                <ExpertList /> 
              </>
            } />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
