import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Connect.css';
import expert1 from '../assets/expert1.png';
import expert2 from '../assets/expert2.png';
import expert3 from '../assets/expert3.png';
import expert4 from '../assets/expert4.png';
import expert5 from '../assets/expert5.png';
import expert6 from '../assets/expert6.png';

const generateShapes = () => {
  const shapes = [];
  for (let i = 0; i < 10; i++) {
    const size = Math.random() * 50 + 10;
    const top = Math.random() * 100;
    const left = Math.random() * 100;
    const shapeClass = Math.random() > 0.5 ? 'shape circle' : 'shape square';
    shapes.push({
      id: i,
      className: shapeClass,
      style: {
        width: size,
        height: size,
        top: `${top}%`,
        left: `${left}%`,
      }
    });
  }
  return shapes;
};

function Connect() {
  const [shapes] = useState(generateShapes());
  const navigate = useNavigate();

  const navigateToExperts = () => {
    navigate('/experts');
  };

  useEffect(() => {
    const handleScroll = () => {
      const backgroundMask = document.querySelector('.background-mask');
      const scrollY = window.scrollY;
      backgroundMask.style.transform = `translate(-50%, -50%) rotate(${180 + scrollY * 0.1}deg)`;
      backgroundMask.style.opacity = `${0.05 + scrollY * 0.001}`;

      const textContent = document.querySelectorAll('.text-content h1, .text-content p, .text-content button');
      textContent.forEach((el) => {
        el.style.transform = `translateY(${scrollY * 0.1}px)`;
      });

      const experts = document.querySelectorAll('.expert');
      experts.forEach((el) => {
        el.style.transform = `translateY(${scrollY * 0.05}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="connect">
      <div className="background-mask"></div>
      <div className="overlay">
        <div className="text-content">
          <h1>Connect with Verified Experts</h1>
          <p>Making informed decisions has never been easier.</p>
          <button onClick={navigateToExperts}>Contact Expert</button>
        </div>
        <div className="experts">
          <img src={expert1} alt="Expert 1" className="expert expert1" />
          <img src={expert2} alt="Expert 2" className="expert expert2" />
          <img src={expert3} alt="Expert 3" className="expert expert3" />
          <img src={expert4} alt="Expert 4" className="expert expert4" />
          <img src={expert5} alt="Expert 5" className="expert expert5" />
          <img src={expert6} alt="Expert 6" className="expert expert6" />
        </div>
        {shapes.map(shape => (
          <div
            key={shape.id}
            className={shape.className}
            style={shape.style}
          ></div>
        ))}
      </div>
    </section>
  );
}

export default Connect;
