import React, { useEffect, useState } from 'react';
import './Categories.css';
import carconsult from '../assets/carconsult.png'; 
import homeconsult from '../assets/homeconsult.png'; 
import comingsoon from '../assets/comingsoon.png'; 

const generateShapes = () => {
  const shapes = [];
  for (let i = 0; i < 10; i++) {
    const size = Math.random() * 50 + 10;
    const top = Math.random() * 100;
    const left = Math.random() * 100;
    const shapeClass = Math.random() > 0.5 ? 'shape circle' : 'shape square';
    shapes.push({
      id: i,
      className: shapeClass,
      style: {
        width: size,
        height: size,
        top: `${top}%`,
        left: `${left}%`,
      }
    });
  }
  return shapes;
};

function Categories() {
  const [shapes] = useState(generateShapes());

  useEffect(() => {
    const handleScroll = () => {
      const textContent = document.querySelectorAll('.text-content h1, .text-content p, .text-content button');
      textContent.forEach((el) => {
        el.style.transform = `translateY(${window.scrollY * 0.1}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="categories">
      <h2>Choose Our Categories</h2>
      <p>Select the area where you need expert advice</p>
      <div className="category-list">
        <div className="category-item">
          <img src={carconsult} alt="Car Consultation" />
          <h3>Car Consultation</h3>
          <ul>
            <li>Choosing the Right Car</li>
            <li>Comparing Options</li>
            <li>Understanding Car Features</li>
            <li>Maintenance and After-Sales Service</li>
            <li>Resale Value</li>
          </ul>
        </div>
        <div className="category-item">
          <img src={homeconsult} alt="Home Consultation" />
          <div className="category-item-overlay">
            <h3>Coming Soon</h3>
          </div>
          <h3>Home consultation</h3>
          <ul>
            <li>Investment Returns</li>
            <li>Flat vs. Land</li>
            <li>Real Estate vs. Mutual Fund Returns</li>
            <li>Location Analysis</li>
            <li>Property Comparison</li>
          </ul>
        </div>
        <div className="category-item">
          <img src={comingsoon} alt="Coming Soon" />
          <h3>Coming Soon: Experts to Guide You On</h3>
          <ul>
            <li>Financial Planning</li>
            <li>Taxation</li>
            <li>Becoming a YouTuber</li>
            <li>Career Growth</li>
            <li>Education and College Choices</li>
          </ul>
        </div>
      </div>
      {shapes.map(shape => (
        <div
          key={shape.id}
          className={shape.className}
          style={shape.style}
        ></div>
      ))}
    </section>
  );
}

export default Categories;
