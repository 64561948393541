import React, { useState } from 'react';
import './ExpertList.css';
import Prince from '../assets/Prince.jpeg';
import Akshay from '../assets/Akshay.jpeg';
import Nikhil from '../assets/Nikhil.jpeg';
import Girish from '../assets/Girish.jpeg';
import Gaurav from '../assets/Gaurav.jpg';
import Paras from '../assets/Paras.jpeg';
import YouTube from '../assets/Youtube.png';
import Instagram from '../assets/Instagram.png';
import QR from '../assets/QR.jpg';

const experts = [
  {
    name: 'Prince Singh',
    title: 'Car Consultant',
    description: 'I am a driving enthusiast with 15 years of experience in the car industry, specializing in sales. I have in-depth knowledge of all car categories. I believe buying a car is a significant investment, and people should be able to make the right decision without regret. Please reach out to me for any guidance.',
    instagram: 'https://www.instagram.com/the.badva.rascal?igsh=bTdiYnRtdmN3bDNv',
    price: '1000',
    duration: '30 Minutes Call',
    image: Prince,
  },
  {
    name: 'Akshay Dhawan',
    title: 'Car Consultant',
    description: 'As a passionate car enthusiast with years of experience and over 1400 subscribers on YouTube, I am dedicated to helping you navigate the car buying process with ease and confidence. Whether you are a first-time buyer or a seasoned car owner, my personalized consultation service offers expert advice tailored to your needs. From selecting the right make and model to understanding financing options and negotiating the best deal, I am here to guide you every step of the way.',
    youtube: 'https://www.youtube.com/@turborevv',
    price: '600',
    duration: '30 Minutes Call',
    image: Akshay,
  },
  {
    name: 'Nikhil Gupta',
    title: 'Car Consultant',
    description: 'I am a car expert with presence on youtube and have over 3000 subscribers and a PAN India WhatsApp group. I specialise in reviewing cars and especially EV as I own one too. I guarantee best suggestion that will suit your needs and requirements. Rest is for you to judge and decide.',
    youtube: 'https://www.youtube.com/@nickreviewskol',
    price: '649',
    duration: '30 Minutes Call',
    image: Nikhil,
  },
  {
    name: 'Girish Khanna',
    title: 'Car Consultant',
    description: 'I am a car enthusiast and YouTuber with over 3100 subscribers. I have helped many people make informed car-buying decisions through my detailed reviews and buying guides. I make ownership experience videos and can surely help you decide the right car for you and your family.',
    youtube: 'https://www.youtube.com/@girishkhanna',
    price: '450',
    duration: '30 Minutes Call',
    image: Girish,
  },
  {
    name: 'Gaurav Arora',
    title: 'Car Consultant',
    description: 'I am a driving enthusiast dedicated to helping people make car-buying decisions based on their priorities. As a YouTube influencer with over 250 videos and a reach of over 9 million, I provide expert guidance to ensure you choose the right car.',
    youtube: 'https://youtube.com/@sevencreations',
    price: '1000',
    duration: '30 Minutes Call',
    image: Gaurav,
  },
  {
    name: 'Paras Gaba',
    title: 'Car Consultant',
    description: 'Car enthusiast and YouTube influencer with over 15 million views and 30K+ subscribers, known for providing honest and detailed reviews of cars across all categories. I can help you make the right choice by understanding your specific needs.',
    youtube: 'https://www.youtube.com/@ParasGabaa/videos',
    price: '1000',
    duration: '30 Minutes Call',
    image: Paras,
  },
];

const ExpertList = () => {
  const [selectedExpert, setSelectedExpert] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);

  const handleClick = (expert) => {
    setSelectedExpert(expert);
  };

  const handleClose = () => {
    setSelectedExpert(null);
    setBookingInfo(null);
  };

  const handleBookNow = (expert) => {
    setBookingInfo(expert);
  };

  return (
    <div>
      <div className="floating-shape shape1"></div>
      <div className="floating-shape shape2"></div>
      <div className="floating-shape shape3"></div>
      <div className="expert-list">
        {experts.map((expert, index) => (
          <div key={index} className="expert-card">
            <div className="expert-image-container">
              <img src={expert.image} alt={expert.name} className="expert-image" />
              {expert.name === 'Prince Singh' ? (
                <a href={expert.instagram} target="_blank" rel="noopener noreferrer">
                  <img src={Instagram} alt="Instagram" className="social-icon" />
                </a>
              ) : (
                <a href={expert.youtube} target="_blank" rel="noopener noreferrer">
                  <img src={YouTube} alt="YouTube" className="social-icon" />
                </a>
              )}
            </div>
            <div className="expert-card-content">
              <h3>{expert.name}</h3>
              <p><strong>Title:</strong> {expert.title}</p>
              <p>
                {expert.description.length > 150 ? (
                  <>
                    {expert.description.substring(0, 150)}...
                    <span className="read-more" onClick={() => handleClick(expert)}>Read more</span>
                  </>
                ) : (
                  expert.description
                )}
              </p>
              <p><strong>Price:</strong> Rs {expert.price}</p>
              <p><strong>Duration:</strong> {expert.duration}</p>
              <button className="book-now-btn" onClick={() => handleBookNow(expert)}>Book Now</button>
            </div>
          </div>
        ))}
      </div>

      {selectedExpert && (
        <div className="modal" onClick={handleClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleClose}>&times;</span>
            <h3>{selectedExpert.name}</h3>
            <p><strong>Title:</strong> {selectedExpert.title}</p>
            <p>{selectedExpert.description}</p>
            <p><strong>Price:</strong> Rs {selectedExpert.price}</p>
            <p><strong>Duration:</strong> {selectedExpert.duration}</p>
            {selectedExpert.name === 'Prince Singh' ? (
              <a href={selectedExpert.instagram} target="_blank" rel="noopener noreferrer">
                <img src={Instagram} alt="Instagram" className="social-icon" />
              </a>
            ) : (
              <a href={selectedExpert.youtube} target="_blank" rel="noopener noreferrer">
                <img src={YouTube} alt="YouTube" className="social-icon" />
              </a>
            )}
          </div>
        </div>
      )}

      {bookingInfo && (
        <div className="modal" onClick={handleClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleClose}>&times;</span>
            <h3>How to Complete Your Booking</h3>
            <p><strong>1. Send Payment:</strong> Pay ₹{bookingInfo.price} using the QR code or UPI address provided - "saurabh.acharya4@okhdfcbank"</p> <img src={QR} alt="QR" /> 
            <p><strong>2. Payment Confirmation:</strong> After completing the payment, send a screenshot of the successful transaction to this WhatsApp number: +91-9930069784.</p>
            <p><strong>3. Schedule Your Consultation:</strong> Once we verify your payment, we will send you the Calendly link of the expert. You can then select the available time slot that suits you.</p>
            <p><strong>4. Expert Consultation:</strong> The expert will call you at the scheduled time on the provided phone number.</p>
            <p><strong>5. Need Help?</strong> If you have any questions or need assistance, feel free to reach out to us at +919930069784.</p>
            <p><strong>Note:</strong> This is the current process for our beta product. We are actively working on integrating seamless payment and scheduling features into our platform to enhance your experience. Thank you for your understanding and support!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpertList;
