import React, { useEffect, useState } from 'react';
import './HowItWorks.css';
import icon1 from '../assets/icon1.svg';
import icon2 from '../assets/icon2.svg';
import icon3 from '../assets/icon3.svg';
import icon4 from '../assets/icon4.svg';

const generateShapes = () => {
  const shapes = [];
  for (let i = 0; i < 10; i++) {
    const size = Math.random() * 50 + 10;
    const top = Math.random() * 100;
    const left = Math.random() * 100;
    const shapeClass = Math.random() > 0.5 ? 'shape circle' : 'shape square';
    shapes.push({
      id: i,
      className: shapeClass,
      style: {
        width: size,
        height: size,
        top: `${top}%`,
        left: `${left}%`,
      }
    });
  }
  return shapes;
};

function HowItWorks() {
  const [shapes] = useState(generateShapes());

  useEffect(() => {
    const handleScroll = () => {
      const textContent = document.querySelectorAll('.text-content h1, .text-content p, .text-content button');
      textContent.forEach((el) => {
        el.style.transform = `translateY(${window.scrollY * 0.1}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="how-it-works">
      <h2>How it works</h2>
      <div className="steps">
        <div className="step">
          <img src={icon1} alt="Find Expert" />
          <h3>Find Expert</h3>
          <p>Browse through our list of verified experts to find the right match for your needs.</p>
        </div>
        <div className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M17.25 12l-6.25 6.25 1.42 1.42 8.67-8.67-8.67-8.67-1.42 1.42 6.25 6.25h-17.25v2h17.25z" fill="#00a2ff"/>
          </svg>
        </div>
        <div className="step">
          <img src={icon2} alt="Book Consultation" />
          <h3>Book Consultation</h3>
          <p>Select a convenient date and time, then enter your name, phone number, and verify OTP.</p>
        </div>
        <div className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M6.75 12l6.25-6.25-1.42-1.42-8.67 8.67 8.67 8.67 1.42-1.42-6.25-6.25h17.25v-2h-17.25z" fill="#00a2ff"/>
          </svg>
        </div>
        <div className="step">
          <img src={icon3} alt="Make Payment" />
          <h3>Make Payment</h3>
          <p>Make the payment as per the instructions securely via UPI.</p>
        </div>
        <div className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M17.25 12l-6.25 6.25 1.42 1.42 8.67-8.67-8.67-8.67-1.42 1.42 6.25 6.25h-17.25v2h17.25z" fill="#00a2ff"/>
          </svg>
        </div>
        <div className="step">
          <img src={icon4} alt="Get Experts" />
          <h3>Get Experts</h3>
          <p>Your chosen expert will call you at the scheduled time.</p>
        </div>
      </div>
      {shapes.map(shape => (
        <div
          key={shape.id}
          className={shape.className}
          style={shape.style}
        ></div>
      ))}
    </section>
  );
}

export default HowItWorks;
