import React from 'react';
import './Header.css';
import logo from '../assets/logo.png'; 

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <img src={logo} alt="ExpertMate Logo" className="logo" />
        <div className="header-text">ExpertMate</div>
      </div>
    </header>
  );
}

export default Header;
