import React from 'react';
import './Footer.css';
import twitter from '../assets/twitter.png';
import facebook from '../assets/facebook.png';
import linkedin from '../assets/linkedin.png';

function Footer() {
  return (
    <footer className="footer">
      <p>Coming Soon</p>
      <div className="social-links">
        <a href="#">
          <img src={twitter} alt="Twitter"/>
        </a>
        <a href="https://www.facebook.com/profile.php?id=61563163046154">
          <img src ={facebook} alt="facebook"/>
        </a>
        <a href="#">
          <img src ={linkedin} alt ="linkedin"/>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
